import './App.css';
import ContactLinks from './ContactLinks';

import {BrowserRouter as Router, Routes, Route, Link, useLocation} from 'react-router-dom';
import React from 'react';
import NeoBankPlaceholder from './NeoBankPlaceholder';
import Project from "./components/Project/Project";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProjectCarousel from "./components/ProjectCarousel";
import Projects from "./components/Projects/Projects";


function App() {
    return (
        <Router>
            <div className="App">
                <div className="cover">
                    {/*<Breadcrumbs/>*/}
                </div>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    {/*<Route path="/neo-bank" element={<NeoBankPlaceholder/>}/>*/}
                </Routes>
            </div>
        </Router>
    );
}

function Breadcrumbs() {
    const location = useLocation();
    const pathname = location.pathname;
    const pathNames = pathname.split('/').filter((x) => x);

    const breadcrumbNameMap = {
        '/neo-bank': 'Мобильное приложение для необанка',
    };

    return (
        <div className="breadcrumbs">
            <Link to="/">
                <img src="/pics/profile-photo.png" alt="User" className="breadcrumb-profile-photo"/>
                Главная
            </Link>
            {pathNames.map((value, index) => {
                const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                return (
                    <span key={to}>
                        {' > '}
                        <Link to={to}>{breadcrumbNameMap[to]}</Link>
                    </span>
                );
            })}
        </div>
    );
}


function Home() {
    return (
        <div className="content">
            <img src="/pics/profile-photo.png" alt="User" className="user-photo"/>
            <h1>Яна Дядченко</h1>
            <p className="location-text">Краснодар (удалёнка/релокация)</p>

            <p className="designer-description">
                Продуктовый дизайнер с бэкграундом в архитектуре. Люблю решать сложные задачи, понимаю важность
                нахождения баланса между опытом{' '}
                <span className="highlight-users">пользователей</span> и целями{' '}
                <span className="highlight-business">бизнеса</span>.
            </p>

            <ContactLinks/> {}

            <h2>Навыки</h2>
            <p className="location-text"> Figma <strong>·</strong> 3ds Max <strong>·</strong> Photoshop{' '}
                <strong>·</strong> Tilda <strong>·</strong> Midjourney{' '}
                <strong>·</strong> Jira <strong>·</strong> Miro <strong>·</strong>{' '}
                Notion <strong>·</strong> Английский (B2)</p>


            <div className="about-section">
                <div className="column">
                    <h3>Харды</h3>
                    <ul>
                        <li>Стили, компоненты, auto layout</li>
                        <li>Прототипирование на переменных</li>
                        <li>Работа с дизайн-системами</li>
                        <li>UX-исследования</li>
                        <li>CJM, User Stories, JTBD</li>
                        <li>Знание HIG, Material Design</li>
                    </ul>
                </div>
                <div className="column">
                    <h3>Софты</h3>
                    <ul>
                        <li>Внимание к деталям, здоровый перфекционизм</li>
                        <li>Эмпатия как инструмент решения проблем</li>
                        <li>Умение принимать и обрабатывать обратную связь</li>
                        <li>
                            Умение структурировать большие объёмы разрозненных данных, вычленять
                            важное, уточнять детали
                        </li>
                    </ul>
                </div>
            </div>

            <Projects />


            <h2>Обо мне</h2>
            <div className="about-section">
                <div className="column">
                    <h3>Развиваюсь в дизайне</h3>
                    <ul>
                        <li>Читаю книги и статьи про дизайн и исследования (Habr, VC, DSGNERS)</li>
                        <li>Слежу за тг-каналами дизайнеров, студий и продуктов (Т-банк, Ozon, Яндекс, Avito и
                            др.)
                        </li>
                        <li>Прокачиваю UI (UI-клуб InternSheep) и UX (Дизайн домашка)</li>
                        <li>Прошу и получаю фидбэк в дизайн-сообществе на свою работу, общаюсь с более опытными
                            дизайнерами
                        </li>
                    </ul>
                </div>
                <div className="column">
                    <h3>Опыт в смежных сферах</h3>
                    <ul>
                        <li>Работала в условиях многозадачности на стройке стадиона «Краснодар»</li>
                        <li>Ежедневно коммуницировала со специалистами смежных разделов в проектном бюро</li>
                        <li>Работала с программами для 3D-моделирования и визуализации</li>
                        <li>Участвовала в разработке информационных моделей на основе предоставленных чертежей</li>
                    </ul>
                </div>
            </div>


            <div className="highlight-box">
                <span role="img" aria-label="airplane">✈️</span>
                <p>Road warrior со стажем: однажды полгода путешествовала по Юго-Восточной Азии, а всего побывала в
                    16 странах</p>
            </div>


            <h2>Обучение</h2>

            <ul>
                <li>
                    <strong>Яндекс Практикум — Дизайнер интерфейсов плюс</strong>
                    <br/>
                    2023–2024
                </li>
                <li>
                    <strong>КубГУ — Факультет архитектуры и дизайна</strong>
                    <br/>
                    Кафедра архитектуры, 2008–2014
                </li>
            </ul>

            <h3>Интенсивы</h3>

            <ul>
                <li>
                    <strong>Formfactor — Основы продуктового дизайна</strong>
                    <br/>
                    Сентябрь, 2024
                </li>
            </ul>

            <ContactLinks/> {}
        </div>
    );
}

export default App;
