import React from 'react';
import "./Project.css"

const Project = ({imgSrc, altText, projectLink, description}) => (
    <div className="project-container">
        <div className="project">
            <a href={projectLink}>
                <img src={imgSrc} alt={altText} className="project-image"/>
            </a>
            <p>
                <strong>
                    {projectLink ? (
                        <a href={projectLink}>{description}</a>
                    ) : (
                        description
                    )}
                </strong>
            </p>
        </div>
    </div>
);

export default Project;
