import React from 'react';
import Slider from "react-slick";
import Project from "./Project/Project";

const ProjectCarousel = ({projects}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        centerMode: true, // Включает центральный режим
        centerPadding: '50px',
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <Slider {...settings}>
            {projects.map((project, index) => (
                <Project
                    key={index}
                    imgSrc={project.imgSrc}
                    altText={project.altText}
                    projectLink={project.projectLink}
                    description={project.description}
                />
            ))}
        </Slider>
    );
};

export default ProjectCarousel;
