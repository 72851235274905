import React from 'react';
import './App.css';

function ContactLinks() {
    return (
        <p className="contact-links">
            <strong>
                <a href="mailto:diadchenko-yana@yandex.com">Почта</a>
            </strong>{' '}
            <strong>·</strong>{' '}
            <strong>
                <a href="https://t.me/Jankel">Telegram</a>
            </strong>
            <a
                href="https://drive.google.com/file/d/1a5IhSp_g7Y7u9uuR2EApOL2jfhRVWDkd/view?usp=sharing"
                className="resume-link"
            >
                Скачать резюме →
            </a>
        </p>
    );
}

export default ContactLinks;
